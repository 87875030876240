import { Container } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SupportButtons from '../../components/SupportButtons';
import { Outlet } from 'react-router-dom';
import { useAccountState } from '../../context/AccountContext';
import { isBrowser, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import WelcomeHeading from '../WelcomeHeading/WelcomeHeading';

const Layout = () => {
  const { user } : any = useAccountState();
  const { t } = useTranslation();

  return (
    <>
      <Header user={user} />
      <Container fluid className={`welcome-wrapper scroller-1 ${isMobile ? 'mobile' : ''}`}>
        <WelcomeHeading />
        <section className="welcome-content">
          <Outlet />
        </section>
        <SupportButtons />
      </Container>
      <Footer/>
    </>
  )
}

export default Layout;
