// @ts-nocheck
import './UserProfile.css';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import UserAvatar from 'react-user-avatar';
import { useTranslation } from 'react-i18next';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className="d-flex justify-content-center align-items-center header-text"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick&& onClick(e);
    }}
  >
    {children}
  </a>
));

const getNameAbbri = name => {
  const split = name.toUpperCase().split(" ");
  if (split.length === 1) {
    return split[0];
  } else {
    return [split[0], split[split.length - 1]].join(' ');
  }
}

const UserProfile = ({ user }) => {
  const { t } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  if (!user) return null;
  const avatarUrl = /^http/.test(user?.avatar) ? user?.avatar : undefined;
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          {isDesktopOrLaptop && <span className="">{ user ? user.name : '' }</span>}
          <div className="user-profile-wrapper">
            {user?.name && <UserAvatar size="40" name={getNameAbbri(user.name)} src={avatarUrl} />}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#" onClick={() => window.open(`${process.env.REACT_APP_ID_DOMAIN}/auth/realms/production.antbuddy.com/account/`, '_blank')}>
            {t('profile')}
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={() => window.open(`https://${process.env.REACT_APP_HOST}/signout`, '_self')}>
            {t('logout')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default UserProfile;
