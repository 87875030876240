import * as Organization from './list/organization';
import * as User from './list/user';
import * as Helper from './apiHelper';

const API = {
  Organization,
  User,
  Helper
};

export default API;
