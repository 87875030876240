import './Welcome.css';
import { Row, Col } from 'react-bootstrap';
import ProductCard from '../../components/ProductCard';
import LogoAR from '../../imgs/LogoAntRing.png';
import LogoAC from '../../imgs/LogoAntCRM.png';
import { useEffect } from 'react';
import API from '../../apis';
import Cookies from 'js-cookie';
import { useAccountFunctions, useAccountState } from '../../context/AccountContext';
import { chooseService } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

const Welcome = () => {
  const { updateOrg, updateOrgCrm } = useAccountFunctions();
  const { t } = useTranslation();
  const { user, org, isAllowTrial, isEnabledCustomerService, isEnabledSales } : any = useAccountState();

  const productCards = [
    {
      title: t('cloudCallCenter'),
      image: LogoAR,
      borderColor: "#35358D",
      onMore: () => window.open("https://www.antbuddy.com/call-center-antring", "_blank"),
      onMoreDisable: () => window.open("https://www.antbuddy.com/call-center-cho-chuoi", "_blank"),
      recommend: 1,
      isEnabled: true,
      isAllowTrial: true,
      onClick: () => window.open(process.env.REACT_APP_ANTRING, '_self'),
    },
    {
      title: t('omnichannelCustomerService '),
      image: LogoAC,
      borderColor: "#E91C6E",
      onMore: () => window.open(process.env.REACT_APP_ANTCRM, "antcrm"),
      onMoreDisable: () => window.open("https://www.antbuddy.com/cham-soc-khach-hang-da-kenh", "_blank"),
      recommend: 1,
      isEnabled: isEnabledCustomerService,
      isAllowTrial: isAllowTrial,
      onClick: () => chooseService('CUSTOMER_SERVICE', {
        org, isEnabledCustomerService, isEnabledSales,
      }),
    },
    {
      title: t('omnichannelRetailSolution'),
      image: LogoAC,
      borderColor: "#35358D",
      onMore: () => window.open(process.env.REACT_APP_ANTCRM, "antcrm"),
      onMoreDisable: () => window.open("https://www.antbuddy.com/quan-ly-ban-hang-da-kenh", "_blank"),
      recommend: 0,
      isEnabled: isEnabledSales,
      isAllowTrial: isAllowTrial,
      onClick: () => chooseService('SALE', {
        org, isEnabledCustomerService, isEnabledSales,
      }),
    }
  ];

  useEffect(() => {
    (async () => {
      try {
        const credential = JSON.parse(Cookies.get('credential'));
        const [userId, orgKey] = credential.identity.split('_');

        // get org
        const org = user.listOrgs.find(o => o.key === orgKey);
        updateOrg(org);

        // call api crm
        const { data: orgCrm } = await API.Organization.getOrganizationCrm(org);
        updateOrgCrm(orgCrm);
        
      } catch (err) {
        console.error(err, '[error crm api]');
      }
    })();
  }, []);

  return (
    <div>
      <Row className="justify-content-md-center fade-wrap pt-4">
        {productCards.map((productCard, index) => (
          <Col key={index} xs="12" lg="3" className="text-center margin-bottom-35 d-flex justify-content-center">
            <ProductCard {...productCard} index={index} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Welcome;
