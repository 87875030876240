const lang = {
  'download': 'Download',
  'cloudCallCenter': 'AntRing Cloud Call Center',
  'omnichannelCustomerService ': 'AntCRM Omnichannel Customer Service ',
  'omnichannelRetailSolution': 'Omnichannel Retail Solution',
  'language': 'Language',
  'contactUs': 'Contact us',
  'welcome': 'Welcome to AntBuddy Omnichannel, Integrated Omnichannel Customer Service Solution for Chains & Retail',
  'readMore': 'Read more',
  'useItNow': 'Use it now',
  'tryItNow': 'Try it now',
  'logout': 'Logout',
  'profile': 'User Profile',
};
export default lang;