import { useState, useEffect } from 'react';
import './ProductCard.css';
import { Image } from 'react-bootstrap';
import StarIcon from '../../imgs/star.png';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';

const ProductCard = ({ title, image, borderColor, onMore, onMoreDisable, recommend, onClick, isEnabled, isAllowTrial, index }) => {
  const [isShowing, setIsShowing] = useState(false);
  const { t } = useTranslation();
  const disableCond = !isAllowTrial && !isEnabled;
  useEffect(() => {
    setTimeout(() => {
      setIsShowing(true);
    }, 100 * index);
  }, []);

  return (
    <Transition
      show={isShowing}
      enter={`transition-opacity duration-500`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div className={`product-card-wrapper ${disableCond ? 'disabled' : 'hover:opacity-80'}`} style={{ border: `2px solid ${borderColor}` }}>
        {recommend ? (<div className="product-card-recommend" style={{ border: `2px solid ${borderColor}` }}>
          <Image src={StarIcon} width="20px" />
        </div>) : null}
        <h3>{title}</h3>
        <Image src={image} width="160px" className="product-card-image" />
        <a href="#" className="product-card-link" onClick={() => isEnabled ? onMoreDisable && onMoreDisable() : isAllowTrial ? onMore && onMore() : onMoreDisable && onMoreDisable() }>{t('readMore')}</a>
        <button disabled={disableCond} className="btn btn-block product-card-button" style={{ backgroundColor: borderColor, borderColor }} onClick={() => onClick && onClick()}>
          {isEnabled ? t('useItNow') : t('tryItNow') }
        </button>
      </div>
    </Transition>
  );
};

export default ProductCard;
