const lang = {
  'download': 'Tải ứng dụng',
  'cloudCallCenter': 'Tổng đài cloud',
  'omnichannelCustomerService ': 'Chăm sóc khách hàng đa kênh',
  'omnichannelRetailSolution': 'Bán lẻ đa kênh',
  'language': 'Ngôn ngữ',
  'contactUs': 'Liên hệ tư vấn',
  'welcome': 'Chào mừng bạn đến Antbuddy Omnichannel, giải pháp chăm sóc khách hàng đa kênh và bán lẻ đa kênh tối ưu cho doanh nghiệp chuỗi',
  'readMore': 'Xem thêm',
  'useItNow': 'Dùng ngay',
  'tryItNow': 'Thử ngay',
  'logout': 'Đăng xuất',
  'profile': 'Hồ sơ người dùng',
};
export default lang;
