import { useTranslation } from 'react-i18next';
import { useAccountState, useAccountFunctions } from '../../context/AccountContext';
import { Switch } from '@headlessui/react'
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { language }: any = useAccountState();
  const { updateLanguage } = useAccountFunctions();

  const switchLanguage = () => {
    const newlang = language === 'vn' ? 'us' : 'vn';
    updateLanguage(newlang);
    i18n.changeLanguage(newlang, (err) => {
      if (err) console.error(err, 'can not change language');
    });
  };

  const enabled = language === 'vn';

  return (
    <Switch
      checked={enabled}
      onChange={switchLanguage}
      className={`bg-antbuddy relative inline-flex h-[26px] w-[50px] rounded-sm items-center ml-3`}
    >
      <span className="sr-only">Change language</span>
      {enabled ? <span className="text-white absolute p-1px left-1 text-xs">EN</span> : <span className="text-white absolute right-1 text-xs">VN</span>}
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-[20px] w-50 rounded-sm transform bg-white transition`} 
      >
        {enabled ? <span className="text-antbuddy absolute top-[2px] right-[5px] text-xs">VN</span> : <span className="text-antbuddy absolute top-[2px] left-[5px] text-xs">EN</span>}
      </span>
    </Switch>
  )
};

export default LanguageSwitcher;
