import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useEffect, useState } from 'react';
import API from './apis';
import Layout from './components/Layout';
import Welcome from './pages/Welcome';
import Launchpad from './pages/Launchpad';
import NotFound from './pages/NotFound';
import { useAccountFunctions, useAccountState } from './context/AccountContext';
import { ChatWidget } from "@antbuddy-jsc/chat-widget";

function App() {
  const { isLoggedIn } : any = useAccountState();
  const { updateUser, updateIsLoggedIn } = useAccountFunctions();
  const [agent, setAgent] = useState<{ name?: string, email?: string, id?: string }>({
    name: '',
    email: '',
    id: '',
  });

  const checkIsLoggedIn = async () => {
    try {
      const response = await API.User.getUserInfo();
      updateUser(response.data);

      // Agent for papercups
      setAgent({
        email: response.data.email,
        name: response.data.name,
        id: response.data.key,
      });
      
      updateIsLoggedIn(true);
    } catch (err: any) {
      console.error(err, '[err]');
      if (err === 'Not found token' || err.response.status === 401) {
        window.location.href = `${process.env.REACT_APP_DOMAIN}/signout`;
      }
    }
  };

  useEffect(() => {
    checkIsLoggedIn();
  }, []);

  if (!isLoggedIn) return null;

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Launchpad />} />
            <Route path="welcome" element={<Welcome />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {agent?.email && <ChatWidget
        token="cd23238c-0dd2-4f9e-9ce8-683f1df68dbd"
        inbox="61f0daf0-fe6e-4987-bd34-ec14720c3d67"
        title="Chào mừng bạn đến với AntBuddy.com"
        subtitle=" Hãy nhắn tin với chúng tôi!"
        primaryColor="#33358c"
        greeting="Xin chào! Bạn cần AntBuddy trợ giúp gì?"
        newMessagePlaceholder="Bắt đầu nhập..."
        showAgentAvailability={false}
        agentAvailableText="Chúng tôi đang online!"
        agentUnavailableText="Chúng tôi đang vắng mặt."
        requireEmailUpfront={false}
        requireNameUpfront={false}
        requirePhoneUpfront={false}
        iconVariant="outlined"
        baseUrl="https://chat-sdk.antbuddy.com"
        iframeUrlOverride="https://chat-widget.antbuddy.com"
        customIconUrl="https://s3-hn-2.cloud.cmctelecom.vn/storage/logo_vi.png"
        lang="vi"
        useCompanyProfileOnly={true}
        customer={{
          name: agent.name,
          email: agent.email,
          external_id: agent.id,
        }}
      />}
    </>
  );
}

export default App;
