// @ts-nocheck
import './ApplicationCard.css';
import React from 'react';
import { Image, Dropdown } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className="d-flex justify-content-center align-items-center text-[#000000]"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick&& onClick(e);
    }}
  >
    {children}
  </a>
));

const AppCard = ({ icon, label, linkTo }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });

  return (
    <div className="application-card group opacity-80 hover:opacity-100" onClick={() => linkTo && window.open(linkTo)}>
      <div className="application-icon bg-white">
        <Image src={icon} />
      </div>
      {isDesktopOrLaptop && <div className="application-label group-hover:text-antbuddy">{label}</div>}
    </div>
  )
}

const ApplicationCard = ({ icon, label, dropdownMenu, linkTo }: {icon: any, label: any, dropdownMenu?: any, linkTo?: any}) => {
  if (dropdownMenu) {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          <AppCard icon={icon} label={label} />
        </Dropdown.Toggle>

        {dropdownMenu}
      </Dropdown>
    )
  }

  return (
    <AppCard icon={icon} label={label} linkTo={linkTo} />
  )
  
}


export default ApplicationCard;
