import './Footer.css';
import BrowserIcon from '../../imgs/browser.png';
import AppStoreIcon from '../../imgs/appstore.png';
import PlayStoreIcon from '../../imgs/playstore.png';
import WindowsIcon from '../../imgs/windows.png';
import LinuxIcon from '../../imgs/linux.png';
import AppleIcon from '../../imgs/apple.png';
import ApplicationCard from '../ApplicationCard';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer-wrapper bg-gradient-to-r from-antbuddy/70 to-antbuddy-nd/70">
      <div className="footer-container">
        <div className="d-flex justify-content-center">
          <h3>{t('download')}</h3>
        </div>
        <div className="d-flex justify-content-center">
          <ApplicationCard icon={AppStoreIcon} label="App Store" linkTo="https://apps.apple.com/vn/app/antbuddy-omnichannel-center/id1501120012" />
          <ApplicationCard icon={PlayStoreIcon} label="Play Store" linkTo="https://play.google.com/store/apps/details?id=com.antbuddy" />
          <ApplicationCard icon={WindowsIcon} label="Windows" linkTo="https://github.com/AntBuddy-Jsc/AntbuddyCallbar/raw/master/AntBuddy%20Callbar%20Setup%202.0.0.exe" />
          <ApplicationCard icon={LinuxIcon} label="Linux" linkTo="https://github.com/AntBuddy-Jsc/AntbuddyCallbar/raw/master/AntBuddy%20Callbar-2.0.0.AppImage" />
          <ApplicationCard icon={AppleIcon} label="Mac OS" linkTo="https://app-updater.antbuddy.com/2.0.0/AntBuddy%20Callbar-2.0.0.dmg" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
