import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AccountProvider } from './context/AccountContext';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/us/translation'
import translationVI from './locales/vn/translation';


i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources: {
    us: {
      translation: translationEN
    },
    vn: {
      translation: translationVI
    }
  },
  lng: "vn", // if you're using a language detector, do not define the lng option
  fallbackLng: "vn",

  interpolation: {
    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  }
});

ReactDOM.render(
  <React.StrictMode>
    <AccountProvider>
      <App />
    </AccountProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
