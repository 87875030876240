import API from '../apis';

export const chooseService = async (serviceName, {
  org, isEnabledCustomerService, isEnabledSales
}) => {
  let service = serviceName;
  if (serviceName === 'SALE' && isEnabledCustomerService) {
    service = 'CS_SALE';
  }

  if (serviceName === 'CUSTOMER_SERVICE' && isEnabledSales) {
    service = 'CS_SALE';
  }

  if (serviceName === 'SALE' && isEnabledSales) {
    window.open(process.env.REACT_APP_ANTCRM, '_self');
    return;
  }

  if (serviceName === 'CUSTOMER_SERVICE' && isEnabledCustomerService) {
    window.open(process.env.REACT_APP_ANTCRM, '_self');
    return;
  }

  try {
    const { data } = await API.Organization.chooseServiceCrm(org, service);
    if (data.success) {
      window.open(process.env.REACT_APP_ANTCRM, '_self');
    }
  } catch (err) {
    console.error(err, 'error choose');
  }
};

